exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-covid-jsx": () => import("./../../../src/pages/covid.jsx" /* webpackChunkName: "component---src-pages-covid-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-orientation-jsx": () => import("./../../../src/pages/orientation.jsx" /* webpackChunkName: "component---src-pages-orientation-jsx" */),
  "component---src-pages-visit-jsx": () => import("./../../../src/pages/visit.jsx" /* webpackChunkName: "component---src-pages-visit-jsx" */),
  "component---src-templates-generic-page-jsx": () => import("./../../../src/templates/GenericPage.jsx" /* webpackChunkName: "component---src-templates-generic-page-jsx" */),
  "component---src-templates-rgp-widget-jsx": () => import("./../../../src/templates/RgpWidget.jsx" /* webpackChunkName: "component---src-templates-rgp-widget-jsx" */)
}

